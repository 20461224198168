import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
    {
        path: '/users',
        component: asyncComponent(() => import('../Users')),
        exact: false
    },
    {
        path: '/logged-in',
        component: asyncComponent(() => import('../Users')),
        exact: false
    },
    {
        path: '/filled-questionnaire',
        component: asyncComponent(() => import('../Users')),
        exact: false
    },
    {
        path: '/did-not-filled-questionnaire',
        component: asyncComponent(() => import('../Users')),
        exact: false
    },
    {
        path: '/questions',
        component: asyncComponent(() => import('../Questionnaire')),
        exact: false
    },
    {
        path: '/sound',
        component: asyncComponent(() => import('../Sound')),
        exact: false
    },
    {
        path: '/category',
        component: asyncComponent(() => import('../WellnessCategory')),
        exact: false
    },
    {
        path: '/content',
        component: asyncComponent(() => import('../Content')),
        exact: false
    },
    {
        path: '/programs',
        component: asyncComponent(() => import('../Programs')),
        exact: false
    },
    {
        path: '/keywords',
        component: asyncComponent(() => import('../KeywordManagement')),
        exact: false
    },
    {
        path: '/manage-category',
        component: asyncComponent(() => import('../Category/Category')),
        exact: false
    },
    {
        path: '/settings',
        component: asyncComponent(() => import('../Settings/Settings')),
        exact: false
    },
    {
        path: '/change-password',
        component: asyncComponent(() => import('../ChangePassword/ChangePassword')),
        exact: false
    },
    {
        path: '/reset-password',
        component: asyncComponent(() => import('../Page/reset-password')),
        exact: false
    },
    {
        path: '/forgot-password',
        component: asyncComponent(() => import('../Page/forgot-password')),
        exact: true
    },
    {
        path: '/404',
        component: asyncComponent(() => import('../Page/404')),
        exact: false
    },
    {
        path: '/dashboard',
        component: asyncComponent(() => import('../Dashboard/index')),
        exact: true
    },
    {
        path: '/admin',
        component: asyncComponent(() => import('../Admin/Admin')),
        exact: false
    },
    {
        path: '/background-images',
        component: asyncComponent(() => import('../BackgroundImages/index.js')),
        exact: true
    },
    {
        path: '/interest',
        component: asyncComponent(() => import('../Interest')),
        exact: false
    },
    {
        path: '/hear-about-us',
        component: asyncComponent(() => import('../HearAboutUs')),
        exact: false
    },
    {
        path: '/tag',
        component: asyncComponent(() => import('../Tag')),
        exact: false
    },
    {
        path: '/content-box',
        component: asyncComponent(() => import('../ContentBox')),
        exact: false
    },
    {
        path: '/subscription',
        component: asyncComponent(() => import('../Subscription/index')),
        exact: false
    },
    {
        path: '/region',
        component: asyncComponent(() => import('../Region/index.js')),
        exact: false
    },
    {
        path: '/country',
        component: asyncComponent(() => import('../Country/index.js')),
        exact: false
    },
    {
        path: '/currency',
        component: asyncComponent(() => import('../Currency/index.js')),
        exact: false
    },
    {
        path: '/static-pages',
        component: asyncComponent(() => import('../StaticPage/index.js')),
        exact: false
    },
    {
        path: '/constants',
        component: asyncComponent(() => import('../Constants/index.js')),
        exact: false
    },
    {
        path: '/feedback',
        component: asyncComponent(() => import('../Feedback/index.js')),
        exact: false
    },
    {
        path: '/daily-wisdom',
        component: asyncComponent(() => import('../DailyWisdom/index.js')),
        exact: false
    },
    {
        path: '/sales-page',
        component: asyncComponent(() => import('../SalesPage/index.js')),
        exact: false
    },
    {
        path: '/language',
        component: asyncComponent(() => import('../Language/index.js')),
        exact: false
    },
    {
        path: '/email',
        component: asyncComponent(() => import('../Email/index.js')),
        exact: false
    },
    {
        path: '/notifications',
        component: asyncComponent(() => import('../Notification/index.js')),
        exact: false
    },
    {
        path: '/referred-users',
        component: asyncComponent(() => import('../ReferredUsers/index.js')),
        exact: false
    },
    {
        path: '/access-control',
        component: asyncComponent(() => import('../AccessControl/index.js')),
        exact: false
    },
    {
        path: '/report-reasons',
        component: asyncComponent(() => import('../ReportReasons/index.js')),
        exact: false
    },
    {
        path: '/',
        component: asyncComponent(() => import('../Widgets/index.js')),
        exact: false
    }
];

class AppRouter extends Component {
    render() {
        const { style } = this.props;
        return (
            <div style={style}>
                <Switch>
                    {routes.map((singleRoute) => (
                        <Route
                            exact={singleRoute.exact}
                            key={singleRoute.path}
                            path={singleRoute.path}
                            {...singleRoute}
                        />
                    ))}
                </Switch>
            </div>
        );
    }
}

export default AppRouter;
